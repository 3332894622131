<template>
  <section>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <b-card>
          <b-card-title>
            <b-row>
              <b-col md="3">
                <div class="d-flex align-items-center">
                  <h3 class="page-title mb-n2">Usuários</h3>
                  <p class="mt-2 mb-n1 ml-3 text-muted">
                    {{ totalRows | numberFilter }}
                  </p>
                </div>
              </b-col>
              <b-col>
                <b-button-toolbar
                  aria-label="Barra de Ferramentas"
                  class="d-flex flex-row-reverse"
                >
                  <b-button-group class="mx-1">
                    <b-dropdown text="Exportar" variant="primary">
                      <template #button-content>
                        <b-spinner
                          v-if="exporting"
                          small
                          type="grow"
                        ></b-spinner>
                        <b-icon
                          v-else
                          icon="download"
                          aria-hidden="true"
                        ></b-icon>
                        Exportar
                      </template>
                      <b-dropdown-item @click="download('ods')"
                        ><i class="fa fa-file-excel-o"></i> Open Document Format
                        (.ODS)
                      </b-dropdown-item>
                      <b-dropdown-item @click="download('xlsx')">
                        <i class="fa fa-file-excel-o"></i> Microsoft Excel
                        (.XLSX)</b-dropdown-item
                      >
                      <b-dropdown-item @click="download('csv')">
                        <i class="fa fa-file-excel-o"></i> Comma-Separated
                        Values (.CSV)</b-dropdown-item
                      >
                      <b-dropdown-item @click="download('pdf')">
                        <i class="fa fa-file-pdf-o"></i> Portable Document Format (.PDF)</b-dropdown-item
                      >
                    </b-dropdown>
                  </b-button-group>
                </b-button-toolbar>
              </b-col>
            </b-row>
          </b-card-title>

          <b-table
            ref="table"
            show-empty
            stacked="md"
            :items="search"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            responsive
            :busy="loadingTable"
          >
            <template #table-busy>
              <div class="text-center text-white my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Carregando...</strong>
              </div>
            </template>

            <template #cell(nome)="row">
              <span class="text-wrap">
                {{ row.item.nome }}
              </span>
            </template>
            <template #cell(email)="row">
              {{ row.item.email }}
              <i
                v-if="row.item.email_verified_at"
                class="mdi mdi-checkbox-marked-circle text-success"
                title="Email Verificado"
                v-b-tooltip.hover
              ></i>
            </template>
            <template #cell(perfil)="row">
              <b-badge
                v-for="role in row.item.roles"
                :key="role.id"
                variant="outline-secondary"
                >{{ role.name }}</b-badge
              >
            </template>
            <template #cell(created_at)="row">
              {{ row.value | formatDateTime }}
            </template>
            <template #cell(actions)="row">
              <b-button
                size="sm"
                variant="primary"
                class="mr-1"
                title="Editar"
                @click="edit(row.item)"
              >
                <i class="mdi mdi-pencil"></i>
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                class="mr-1"
                title="Remover"
                @click="remove(row.item)"
              >
                <i class="mdi mdi-delete"></i>
              </b-button>
            </template>
          </b-table>

          <b-row class="justify-content-md-center">
            <b-col md="5">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="md"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>

    <b-sidebar
      id="sidebar-edit"
      no-title
      bg-variant="dark"
      text-variant="light"
      width="500px"
      shadow
      right
      backdrop
      backdrop-variant="dark"
    >
      <div class="px-3 py-2">
        <h4 class="card-title">Editar Usuário</h4>
        <!-- <p class="card-description">Basic form layout</p> -->
        <form class="forms-sample" @submit.stop.prevent="saveOrUpdate">
          <b-form-group label="Nome" label-for="nomeInput">
            <b-input-group>
              <b-form-input
                id="nomeInput"
                type="text"
                placeholder="Nome"
                v-model="$v.user.nome.$model"
                :state="$v.user.nome.$dirty ? !$v.user.nome.$error : null"
                aria-describedby="nome-live-feedback"
                maxlength="255"
              ></b-form-input>
              <b-form-invalid-feedback id="nome-live-feedback">
                <template v-if="!$v.user.nome.required"
                  >Preenchimento obrigatório</template
                >
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <b-form-group label="Cliente">
            <multiselect
              v-model="user.cliente"
              :options="clientes"
              @search-change="findClientes"
              :loading="carregandoClientes"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="false"
              placeholder="Selecionar Cliente"
              label="nome"
              track-by="id"
              selectLabel="Enter para selecionar"
              selectedLabel="Selecionado"
              deselectLabel="Enter para remover"
            >
              <template slot="noOptions">Digite para pesquisar.</template>
            </multiselect>
          </b-form-group>
          <b-form-group
            label="Perfil"
            description="Selecione pelo menos um perfil."
            v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox-group
              v-model="user.roleNames"
              :options="roles"
              :aria-describedby="ariaDescribedby"
              buttons
              button-variant="outline-primary"
            ></b-form-checkbox-group>
          </b-form-group>
          <div class="d-flex">
            <b-button
              type="submit"
              variant="success"
              class="mr-2"
              :disabled="loading"
            >
              <template v-if="loading">
                <b-spinner small type="grow"></b-spinner>
                Processando...
              </template>
              <template v-else> Enviar </template>
            </b-button>
          </div>
        </form>
      </div>
    </b-sidebar>
  </section>
</template>


<script>
import { required } from "vuelidate/lib/validators";
import User from "@/models/user";
import UserService from "../../services/user.service";
import ClienteService from "../../services/cliente.service";

export default {
  name: "Users",
  data() {
    return {
      fields: [
        { key: "nome", sortable: true },
        { key: "email", sortable: true },
        { key: "cliente.nome", label: "Cliente", sortable: false },
        { key: "perfil", label: "Perfil", sortable: false },
        { key: "created_at", label: "Data Cadastro", sortable: true },
        { key: "actions", label: "Ações" },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 20,
      sortBy: "nome",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      loadingTable: false,
      user: new User(),
      loading: false,
      clientes: [],
      carregandoClientes: false,
      roles: [
        { text: "Cliente Projeto", value: "cliente-projeto" },
        { text: "Cliente B2B", value: "cliente-b2b" },
        { text: "Cliente Doktor", value: "cliente-doktor" },
        { text: "Operador Piattino", value: "operador" },
        { html: "<b class='text-danger'>Administrador</b>", value: "admin" },
      ],
      exporting: false,
    };
  },
  mounted() {
    this.findClientes();
  },
  methods: {
    search(ctx) {
      let params = {
        page: ctx.currentPage,
        perPage: ctx.perPage,
        orderBy: ctx.sortBy,
        sort: ctx.sortDesc ? "desc" : "asc",
      };

      this.loadingTable = true;

      const promise = UserService.getAll(params);

      return promise
        .then((response) => {
          this.loadingTable = false;
          const items = response.data.data;
          this.totalRows = response.data.total;

          // console.log(items);
          return items || [];
        })
        .catch(() => {
          this.loadingTable = false;
          this.totalRows = 0;
        });
    },
    saveOrUpdate() {
      this.loading = true;
      this.$v.user.$touch();
      if (this.$v.user.$anyError) {
        this.loading = false;
        return;
      }

      const promise = UserService.saveOrUpdate(this.user);
      return promise
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            title: "Sucesso!",
            autoHideDelay: 1000,
            toaster: "b-toaster-bottom-left",
            variant: "success",
          });
          this.user = {
            id: null,
            nome: "",
          };
          this.$v.user.$reset();
          this.$refs.table.refresh();
          this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    edit(item) {
      this.user = new User();
      if (item) {
        this.user = {
          id: item.id,
          nome: item.nome,
          cliente: item.cliente,
          roleNames: item.roleNames,
        };
      }
      this.$v.user.$reset();
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
    },
    remove(item) {
      this.$bvModal
        .msgBoxConfirm("Deseja realmente excluir este registro?", {
          size: "md",
          buttonSize: "md",
          okVariant: "success",
          okTitle: "Sim",
          cancelVariant: "outline-secondary",
          cancelTitle: "Cancelar",
          footerClass: "p-2 border-top-0",
          centered: true,
          footerBgVariant: "dark",
        })
        .then((value) => {
          if (value) {
            const promise = UserService.delete(item.id);
            return promise
              .then((response) => {
                this.$bvToast.toast(response.data.message, {
                  title: "Sucesso!",
                  autoHideDelay: 1000,
                  toaster: "b-toaster-bottom-left",
                  variant: "success",
                });
                this.$refs.table.refresh();
              })
              .catch(() => {})
              .then(() => {
                this.loading = false;
              });
          }
        });
    },
    findClientes(query) {
      this.carregandoClientes = true;
      let params = {
        nome: query,
      };
      ClienteService.getAll(params).then((response) => {
        this.clientes = response.data.data;
        this.carregandoClientes = false;
      });
    },
    download(format) {
      this.exporting = true;
      const promise = UserService.download(format);
      return promise
        .then((response) => {
          const blob = new Blob([response.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "users." + format;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {})
        .then(() => {
          this.exporting = false;
        });
    },
  },
  validations: {
    user: {
      nome: {
        required,
      },
    },
  },
};
</script>
